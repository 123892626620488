import React, { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import { Alert, Fab } from "@mui/material"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"

import "./IFrameForm.scss"
import api from "../../api"
// import Nav from "../../components/Nav/Nav.tsx"
// import Footer from "../../components/Footer/Footer.tsx"
// import pledgerBg from "../../assets/images/pledgerBg.jpg"
import ChildForm from "../../components/ChildForm/ChildForm.tsx"
import GuardianForm from "../../components/GuardianForm/GuardianForm.tsx"

export default function Pledge() {
    useEffect(() => {
        let message = {
            height: document.body.scrollHeight,
            width: document.body.scrollWidth,
        }

        window.top?.postMessage(message, '*')
    })

    type FormStatus = "pending guardian data" | "pending child data" | "submitting" | "successfully submitted"

    const [formStatus, setFormStatus] = useState<FormStatus>("pending guardian data")
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const initialGuardianState = {
        id: null,
        first_name: "",
        last_name: "",
        province: "",
        city: "",
        email: ""
    };
    const initialChildrenState = [{
        first_name: "",
        last_name: "",
        school_id: "",
        guardian_id: "",
        grade: "",
        next_school_id: "",
    }]

    const [guardianData, setGuardianData] = useState({ ...initialGuardianState });
    const [childrenData, setChildrenData] = useState([ ...initialChildrenState ]);

    const addNewChild = () => {
        setChildrenData([
            {
                first_name: "",
                last_name: "",
                school_id: "",
                guardian_id: "",
                grade: "",
                next_school_id: "",
            },
            ...childrenData,
        ])
    }

    const signAgain = () => {
        setFormStatus("pending guardian data")
        setGuardianData({ ...initialGuardianState });
        setChildrenData([ ...initialChildrenState ]);
    }

    const showValidationError = (error) => {
        setErrorMessage(error);
    }

    async function submit() {
        try {
            setFormStatus("submitting")

            await api.post("/pledges", { "guardian": guardianData, "children": childrenData })

            setFormStatus("successfully submitted")
        } catch (error) {
            setErrorMessage(error.response.data.error || "An unexpected error occurred. Please try again or contact info@unpluggedcanada.com if the problem persists");
            setFormStatus("pending child data");
        }
    }

    return (
        <div className="flex flex-col bg-white">
            <main className="w-full  px-4 py-5 lg:px-[180px]  lg:py-10 max-w-[1440px] mx-auto">
                <div className=" rounded-lg flex items-center justify-center">
                    <section className="max-w-[1440px] w-full py-8 lg:py-5 px-4 lg:px-8 flex flex-col gap-10">
                        <form className="form">
                            <h1 className="form__title text-[32px] text-[#2e4049]">
                                Unplugged Canada Pledge
                            </h1>

                            {formStatus === "pending guardian data" && (
                                <>
                                    <div className="form__stepper">
                                        <div className="form__step-orb form__step-orb--SELECTED"></div>
                                        <div className="form__step-orb"></div>
                                    </div>
                                    <GuardianForm
                                        guardianData={guardianData}
                                        setGuardianData={setGuardianData}
                                        postGuardian={() => setFormStatus("pending child data")}
                                    />
                                </>
                            )}

                            {formStatus === "pending child data" && (
                                <>
                                    <div className="form__stepper">
                                        <div className="form__step-orb"></div>
                                        <div className="form__step-orb form__step-orb--SELECTED"></div>
                                    </div>

                                    {errorMessage && (
                                        <>
                                            <Alert variant="outlined" severity="error">
                                                {errorMessage}
                                            </Alert>
                                        </>
                                    )}

                                    <ChildForm
                                        onSubmit={submit}
                                        childrenData={childrenData}
                                        setChildrenData={setChildrenData}
                                        addNewChild={addNewChild}
                                        showValidationError={showValidationError}
                                    />
                                </>
                            )}

                            {formStatus === "submitting" && (
                                <Box
                                    position="relative"
                                    className="space-y-8"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ height: "400px" }}
                                >
                                    <CircularProgress variant="indeterminate" />
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="textSecondary"
                                        fontSize="1rem"
                                    >Signing the pledge...</Typography>
                                </Box>
                            )}

                            {formStatus === "successfully submitted" && (
                                <>
                                    <Box
                                        position="relative"
                                        className="space-y-8"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ height: "400px" }}
                                    >
                                        <Alert variant="outlined" severity="success">
                                            Thank you for signing the pledge.
                                        </Alert>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="textSecondary"
                                            fontSize="1rem"
                                        >You will receive a confirmation email shortly</Typography>

                                        <Fab
                                            sx={{ marginTop: 2 }}
                                            style={{ color: "white", width: "10rem" }}
                                            color="primary"
                                            variant="extended"
                                            onClick={signAgain}
                                        >
                                            Sign again
                                        </Fab>
                                    </Box>
                                </>
                            )}
                        </form>
                    </section>
                </div>
            </main>
        </div>
    )
}
