import React from 'react'
import Nav from '../components/Nav/Nav.tsx'
import Footer from '../components/Footer/Footer.tsx'
import hillcrestLogo from '../assets/images/hillcrestLogo.png'
import hillcrestFlyer from '../assets/FlyerHilcrestSchool.jpg'
import genAlphaLogo from '../assets/images/GenAlphaMovementLogo.png'
import communities from '../assets/images/communities.jpeg'
import { PromotionCard } from '../components/ui/PromotionCard.tsx'
import { Button } from '../components/ui/Button.tsx'
import * as Dialog from '@radix-ui/react-dialog'
import { XCircle } from 'lucide-react'
import '../styles/dialog.css'
import { Helmet } from 'react-helmet'

export default function Communities() {
    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>Communities in Action: Other Smartphone Free Actions</title>
                <meta name="description" content="Discover other actions in our communities for a smartphone-free childhood, like Gen Alpha Movement."></meta>
            </Helmet>
            <div className="flex flex-col bg-neutral-100">
                <Nav />
                <div className="flex items-center relative justify-center w-full h-[240px] lg:h-[300px]">
                    <h2 className="text-2xl lg:text-[40px] z-20 font-bold uppercase text-neutral-100">
                        Communities in action
                    </h2>
                    <img
                        src={communities}
                        className="w-full h-full object-cover absolute top-0 right-0"
                        alt="Blue stones wallpaper"
                    />
                </div>
                <main className="w-full px-4 py-5 lg:px-[180px]  lg:py-10 max-w-[1440px] mx-auto space-y-4">
                    <div className="bg-neutral-200 rounded-lg flex items-center justify-center">
                        <section className="max-w-[1440px] w-full py-8 lg:py-5 px-4 lg:px-8 flex flex-col gap-10">
                            <PromotionCard
                                title="Unplugged Canada Webinar"
                                title2="Smartphone-Free Childhood"
                                date='Wednesday, November 27th 2024 - 5pm (Pacific)'
                                pin='Online Zoom webinar'
                            />

                            <div className="flex flex-col gap-8">
                                <div className="flex flex-col gap-6">
                                    <span className="text-[20px] text-gray-700 font-normal">
                                        An AWARENESS webinar featuring Tania Johnson, co-founder of the Institute of Child Psychology
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        Join us to discuss the Unplugged Canada mission, explain the pledge, and delve into why delaying smartphones is so vital!
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        <a
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://zoom.us/webinar/register/WN_Q_WC2upUSGe24ve6UtjIhg">Register now</a>
                                    </span>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="bg-neutral-200 rounded-lg flex items-center justify-center">
                        <section className="max-w-[1440px] w-full py-8 lg:py-5 px-4 lg:px-8 flex flex-col gap-10">
                            <PromotionCard
                                title="The Generation Alpha Movement"
                                title2="Free From Phones"
                                pathImage={genAlphaLogo}
                            />

                            <div className="flex flex-col gap-8">
                                <div className="flex flex-col gap-6">
                                    <span className="text-[20px] text-gray-700 font-normal">
                                        The Gen Alpha Movement is a group of parents committed to delaying smartphones and social media for their children located in St. Albert, Alberta, and surrounding areas. It began as a small group of moms who met weekly at their daughters’ dance class. In the waiting room of that dance studio, they were given the gift of time. Uninterrupted time, where they could chat about and connect over a variety of topics.
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        From teaching to nursing, science, and project leadership, we are passionate and deeply committed to our children and making positive changes in our community. We were all noticing the effects of a “phone-based childhood” personally and professionally.
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        The subject of smartphones and social media continued to come up. We discussed trends in the decline in mental health, exposure to inappropriate content, increase in sextortion, loss of time outside in nature, and how young children were getting personal devices. We began to do more research.
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        After watching <a href="https://youtu.be/He3IJJhFy-I?si=dUHF5aOsD3ORNRdX" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Childhood 2.0</a>, reading <a href="https://www.anxiousgeneration.com/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">The Anxious Generation</a> by Johnathan Haidt, and listening to podcasts such as <a href="https://www.scrolling2death.com/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Scrolling to Death</a>, we felt compelled to raise awareness in our community about the call to delay smartphones and social media until an appropriate age.
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        We are now committed to growing our group by offering parent sessions, providing school rep support, and building local communities of like-minded families committed to delaying smartphones and social media by following Johnathan Haidt’s new norms.
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        We are passionate about supporting and working alongside Unplugged Canada. This is an incredibly important cause. We believe that if we all work together, we can restore a play-based childhood with real-life connections, improving the mental health, academic success, and physical well-being of children across Canada.
                                    </span>

                                    <span className="text-[20px] text-gray-700 font-normal">
                                        The Gen Alpha Team
                                        Hilarie, Jyll, Kirsten, Katryna, Kate, and Sydney
                                    </span>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>

                <Footer />
            </div>
        </>
    )
}
