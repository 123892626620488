import React from 'react'
import Nav from '../components/Nav/Nav.tsx'
import gracePhoto from '../assets/images/grace.JPG'
import ambassadorsHeroImage from '../assets/images/ambassadors.jpeg'
import jennyPhoto from '../assets/images/JennyLinkedinProfileAvatar.png'
import josettePhoto from '../assets/images/JosetteAvatar.png'
import jeffreyPhoto from '../assets/images/JeffreyKimelAvatar.jpg'
import jakeGallingerPhoto from '../assets/images/JacobGallingerAvatar.jpg'
import kirstenSweetPhoto from '../assets/images/KirstenSweetAvatar.jpg'
import margotDenommePhoto from '../assets/images/margotDenommeAvatar.jpeg'

import Footer from '../components/Footer/Footer.tsx'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Helmet } from 'react-helmet'

export default function Ambassadors() {
    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>Ambassadors of Unplugged Canada</title>
                <meta name="description" content="Meet our ambassadors advocating for children to grow in a smartphone-free environment. Spread the word and join us!"></meta>
            </Helmet>
            <div className="flex flex-col bg-neutral-100">
                <Nav />
                <div className="flex items-center relative justify-center w-full h-[240px] lg:h-[300px]">
                    <h2 className="text-2xl lg:text-[40px] z-20 font-bold uppercase text-neutral-100">
                        Ambassadors
                    </h2>
                    <img
                        src={ambassadorsHeroImage}
                        className="w-full h-full object-cover absolute top-0 right-0"
                        alt="Blue stones wallpaper"
                    />
                </div>
                <main className="w-full px-4 py-5 lg:px-[180px]  lg:py-10 max-w-[1440px] mx-auto">
                    <div className="w-full py-5 flex flex-col gap-10">
                        <div className="relative h-[240px] mx-auto w-[240px] rounded-full overflow-hidden">
                            <img
                                src={jennyPhoto}
                                alt="Jenny Perez"
                                className="absolute top-0 right-0 w-full h-full"
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <a
                                href="https://www.linkedin.com/in/perezmckenzie/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="flex gap-[10px]">
                                    <h3 className=" text-2xl hover:underline  text-blue-600 font-bold">
                                        Jenny Perez{' '}
                                    </h3>
                                    <LinkedInIcon
                                        // fontSize="large"
                                        sx={{ color: '#2563EB' }}
                                    />
                                </div>
                            </a>

                            <div className="mt-2">
                                <p className="text-sm text-gray-700">
                                    Master of Business Administration (MBA) –
                                    Richard Ivey School of Business, Canada
                                </p>
                                <p className="text-sm text-gray-700">
                                    Leadership Principles Certificate – Harvard
                                    Business School
                                </p>
                                <p className="text-sm text-gray-700">
                                    Immigration: Laws, Policies and Procedures –
                                    University of British Columbia, Canada
                                </p>
                                <p className="text-sm text-gray-700">
                                    Economist, University of Lima, Peru
                                </p>
                            </div>
                        </div>

                        <p className="text-xl leading-relaxed text-neutral-700">
                            Thank you so much for being here and reading this! I’m
                            Jenny – a mother and an entrepreneur who has been
                            passionate about the impact of online technologies on
                            children’s minds for over a decade. After encountering
                            Brooke Shannon’s{' '}
                            <a
                                href="https://www.waituntil8th.org/"
                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Wait Until 8th
                            </a>{' '}
                            campaign which is active in the United States, I was
                            inspired to start a similar movement here in Canada.
                        </p>

                        <p className="text-xl leading-relaxed text-neutral-700">
                            I’ve travelled to 75 countries, lived in developing
                            countries, and have called Canada my home for 22 years.
                            Alongside this I have spent 15 years watching,
                            listening, and talking with young people nearly every
                            day. As I have worked to learn from different cultures,
                            perspectives, and practices, I’ve maintained an openness
                            and curiosity about the world that has helped me to
                            define my values and recognize what truly matters most.
                        </p>

                        <p className="text-xl leading-relaxed text-neutral-700">
                            One of the things I feel most nostalgic about are the
                            days where we really connected with each other without
                            phones or screens. I know that these technologies are
                            here to stay, and they serve important purposes in our
                            lives. However, it is undeniable that they are overused
                            and that they are introduced too early, and this has
                            caused untold harm to young people. I’ve continued to
                            learn about the effects of smartphones on developing
                            brains (check out our research page), and this has
                            fueled my desire to found Unplugged.
                        </p>
                        <p className="text-xl leading-relaxed text-neutral-700">
                            I want my daughter, and all our children, to enjoy
                            childhood. I want them to be happy – isn’t that the most
                            important thing? I want them to live full lives and to
                            be mentally, socially, and emotionally resilient. When I
                            think about our everyday life, I simply want to enjoy a
                            meal together, feel like a family, and talk to each
                            other. Reducing screen time and delaying the
                            introduction of smartphones supports all these things.
                        </p>
                        <p className="text-xl leading-relaxed text-neutral-700">
                            Doing things differently is possible. I have managed to
                            do this so far with my beautiful nine-year-old daughter,
                            but I know that if we do it together as a community, it
                            will be even easier.
                        </p>
                    </div>

                    <div className="flex flex-col py-5 gap-5 lg:grid lg:grid-cols-2">
                        <section className="flex flex-col gap-6">
                            <div className="relative h-[240px] shadow-md mx-auto w-[240px] rounded-full overflow-hidden">
                                <img
                                    src={gracePhoto}
                                    alt="Jenny Perez"
                                    className="absolute top-0 right-0 w-full h-full"
                                />
                            </div>
                            <a
                                href="https://citypedspecialty.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h3 className=" text-2xl hover:underline lg:text-center text-blue-600 font-bold">
                                    Dr. Grace Yu | MDCM FRCPC Pediatrics
                                </h3>
                            </a>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I fully support the Unplugged Canada initiative. I
                                have been a practicing pediatrician in Vancouver, BC
                                for almost 25 years. For the past 5-10 years, not a
                                day goes by in my practice that I do not have
                                parents describing the struggle they have prying
                                their children off of their cell phones, tablets,
                                computers or away from the television. There is
                                constant conflict in many homes over managing screen
                                time. Countless parents are unable to engage their
                                kids while they are on screen. The more screen time
                                kids have, the more they want.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                We know that youth are experiencing increased mental
                                health challenges including anxiety, depression,
                                emotional dysregulation, ADHD, and sleep challenges.
                                Constant digital stimulation increases mood swings
                                and feelings of tension and irritability.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                Being a child is such a short and precious time in
                                one’s life. Childhood is the chance for kids to be
                                creative, active, and interactive. People have their
                                whole lives to be on screens. Let kids play, laugh,
                                be curious and explore with one another. We should
                                be advocating for less screen times in schools, not
                                more!
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I completed my Medical Degree at McGill University.
                                I moved to Vancouver for my Pediatric Residency
                                training at BC Children’s and Women’s Hospital.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I enjoy teaching pediatric and family medicine
                                residents, and medical students at the hospital. I
                                am a Clinical Assistant Professor in the UBC
                                Department of Pediatrics and have been the recipient
                                of awards for Family Centered Care and Community
                                Pediatrician of the Year from the UBC Department of
                                the Pediatrics. I was also a member of the Pediatric
                                Residency Training Committee for over 10 years and
                                was Assistant Program Director for the Pediatric
                                Residents for one year.
                            </p>
                        </section>

                        <section className="flex flex-col gap-6">
                            <div className="relative h-[240px] mx-auto w-[240px] rounded-full overflow-hidden">
                                <img
                                    src={josettePhoto}
                                    alt="Josette Calleja"
                                    className="absolute top-0 right-0 w-full h-full"
                                />
                            </div>
                            <a
                                href="https://callejacoaching.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h3 className=" text-2xl lg:text-center hover:underline  text-blue-600 font-bold">
                                    Josette Calleja
                                </h3>
                            </a>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I am Josette Calleja, a parent coach, a passionate
                                executive, and wellness specialist. I am a strong
                                supporter of Unplugged because I believe our
                                children need a childhood filled with meaningful
                                connections, outdoor adventures and genuine
                                interactions. The things a cell phone cannot
                                provide. In my practise, I often see children become
                                so absorbed in screens, potentially hindering their
                                development and connection to the world around them.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                Research shows that spending time outdoors and
                                engaging in real-world interactions significantly
                                contributes to a child’s wellbeing, self esteem and
                                overall development. Keeping our kids off the phone
                                at this critical age will provide the opportunity to
                                connect with nature, nurture their curiosity and
                                build authentic relationships offline. Please join
                                us in taking a stand for a cell phone-free childhood
                                so our kids embrace a lifestyle that values genuine
                                connections, imagination and healthy development.
                                Let’s together make a difference in shaping a
                                healthier future for the next generation.
                            </p>
                        </section>

                        <section className="flex flex-col gap-6">
                            <div className="relative h-[240px] mx-auto w-[240px] rounded-full overflow-hidden">
                                <img
                                    src={jeffreyPhoto}
                                    alt="Jeffrey Kimel"
                                    className="absolute top-0 right-0 w-full h-full"
                                />
                            </div>
                            <h3 className=" text-2xl lg:text-center hover:underline  text-blue-600 font-bold">
                                Jeffrey Kimel
                            </h3>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                As parents of four young children, my wife and I are
                                deeply passionate about becoming ambassadors for
                                UnpluggedCanada.com. With our oldest entering the
                                age where smartphones and social media are becoming
                                prevalent, we have read numerous books and articles
                                highlighting the alarming statistics about the
                                negative impacts these technologies can have on
                                young minds.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                We believe that tackling this issue requires a
                                collective effort from society, beginning with
                                parents and extending to school leaders. A bottom-up
                                approach led by parents, combined with a top-down
                                strategy from school administrations, creates a
                                powerful framework for change. We see
                                UnpluggedCanada.com as an incredible tool to help us
                                stay accountable and to provide our children with
                                the best possible environment for success.
                            </p>
                        </section>

                        <section className="flex flex-col gap-6">
                            <div className="relative h-[240px] mx-auto w-[240px] rounded-full overflow-hidden">
                                <img
                                    src={jakeGallingerPhoto}
                                    alt="Jacob Gallinger"
                                    className="absolute top-0 right-0 w-full h-full"
                                />
                            </div>
                            <h3 className=" text-2xl lg:text-center hover:underline  text-blue-600 font-bold">
                                Jacob Gallinger
                            </h3>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                After almost a decade of working in Educational
                                Administration, it has become abundantly clear to me
                                that Smartphones and social media are the two most
                                significant contributors to the recent increase in
                                mental health issues amongst our youth. When senior
                                and experienced teachers comment that “Kids just
                                aren’t the same these days,” they show frustration
                                and fatigue when they follow up their comment with,
                                “It’s because of their phones.” Jonathan Haidt’s
                                book, The Anxious Generation: How the Great Rewiring
                                of Childhood Is Causing an Epidemic of Mental
                                Illness seems to me to be just the catalyst for
                                change that parents and educators have been seeking
                                for many years. Legislators, politicians and the
                                media finally seem to be waking up to these issues.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                As a school principal and father of two young girls,
                                I am worried and frightened for the future of our
                                children if we continue to allow and encourage them
                                to have a “phone-based childhood”. With that said, I
                                am invigorated and energized by Haidt’s suggested
                                new norms. The pendulum seems to be swinging toward
                                a collective acknowledgement that parents and
                                educators must act now if we wish to curb the
                                detrimental effects of Smartphones and social media
                                on today's youth. The Unplugged Canada pledge is
                                timely, important and impactful. There is power in
                                numbers and we hope you will join this critical
                                initiative.
                            </p>
                        </section>

                        <section className="flex flex-col gap-6">
                            <div className="relative h-[240px] mx-auto w-[240px] rounded-full overflow-hidden">
                                <img
                                    src={kirstenSweetPhoto}
                                    alt="Kirsten Sweet"
                                    className="absolute top-0 right-0 w-full h-full"
                                />
                            </div>
                            <h3 className=" text-2xl lg:text-center hover:underline  text-blue-600 font-bold">
                                Kirsten Sweet
                            </h3>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                Hello!
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I am Kirsten Sweet, a mother of two young children
                                and a teacher. I have a master’s degree in
                                elementary education along with undergraduate
                                degrees in education and psychology. In the past 16
                                years that I have spent in the classroom, I have
                                become increasingly concerned with how smartphones
                                and social media are changing the essence of
                                childhood.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I witness children, for example, as young as eight
                                years old, walking home from school, scrolling on
                                their smartphones. I think of the nuances of daily
                                life that they are missing. The squirrel running
                                across a branch, the bird chirping happily in the
                                background, the other eight year old walking across
                                the street who glances over - a potential new
                                friend, unnoticed.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                As parents, we have the power and responsibility to
                                slow this down for our children. Together, we can
                                gain a better understanding of how to safely
                                introduce smartphones and social media to children
                                at an older age. If we do this collectively, we can
                                shift societal norms and reduce the pressure to give
                                a smartphone to our children at a young age because
                                most of their peers have one.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                We can flip the script on smartphones. Instead of
                                most of the students in a grade having a smartphone
                                and social media access, parents can collaborate to
                                delay, so most of the students in a grade do not
                                have a smartphone and social media access. By
                                signing the pledge, we are taking an important step.
                                This is in our hands.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                I wholeheartedly support the Unplugged Canada
                                movement, dedicated to raising awareness and
                                fostering communities of families that empower one
                                another in their choice to postpone the use of
                                smartphones and social media.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                We must protect our children from the potential
                                dangers of unrestricted online access. By sharing
                                this goal of delaying smartphone and social media
                                use, we can restore a play-based childhood and
                                safeguard our children's well-being. I am proud to
                                support Unplugged Canada and follow Jonathan Haidt’s
                                new norms. Together, we can make a difference.
                            </p>
                        </section>

                        <section className="flex flex-col gap-6">
                            <div className="relative h-[240px] mx-auto w-[240px] rounded-full overflow-hidden">
                                <img
                                    src={margotDenommePhoto}
                                    alt="Margot L. Denomme"
                                    className="absolute top-0 right-0 w-full h-full"
                                />
                            </div>
                            <h3 className=" text-2xl lg:text-center hover:underline  text-blue-600 font-bold">
                                Margot L. Denommé
                            </h3>

                            <p className="text-xl leading-relaxed text-neutral-700">
                                I am an author, speaker, parent, and former Crown
                                Attorney. Throughout my career, I have witnessed the
                                devastating effects of low self-esteem and
                                diminished confidence in children. As a mother of
                                two daughters, I became deeply concerned about the
                                unrealistic beauty standards perpetuated by
                                mainstream and social media. Recognizing the link
                                between damaged self-worth, bullying, mental health
                                issues, and future challenges, I turned to writing
                                children's stories with a powerful message: true
                                beauty comes from within. In 2013, I launched 'The
                                Celebrate YOU!' Tour, reaching over 150 schools and
                                community groups across North America. My work
                                focuses on empowering children by promoting
                                confidence, digital etiquette, and online safety.
                            </p>
                            <p className="text-xl leading-relaxed text-neutral-700">
                                Most recently, I introduced The Family Smartphone
                                Guide, a vital resource for parents navigating the
                                complexities of their children's digital lives. This
                                guide provides practical tools for setting
                                boundaries, understanding online risks, and offers
                                essential insights into the physical, mental, and
                                legal implications of smartphone use.
                            </p>
                        </section>
                    </div>
                </main>

                <Footer />
            </div>
        </>
    )
}
