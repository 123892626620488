import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/unplugged_vertical_logo.svg'
import logoMobile from '../../assets/images/unplugged_horizontal_logo.svg'
import { Button } from '../ui/Button.tsx'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '../ui/Sheet.tsx'
import { Menu } from 'lucide-react'
import { Alert, Collapse, Button as MuiButton } from '@mui/material'

const BANNER_ID = "20241113";
const BANNER_STORAGE_KEY = `unplugged:banner-${BANNER_ID}-hidden`;

const paths = [
    'Pledge',
    'Research',
    'Ambassadors',
    'Resources',
    'FAQs',
    'Government',
    'Communities',
]


export default function Nav() {
    const navigate = useNavigate()
    const [bannerOpen, setBannerOpen] = useState(() => {
        const storedValue = localStorage.getItem(BANNER_STORAGE_KEY);
        const closed = storedValue ? JSON.parse(storedValue) : false;
        return !closed;
    });

    const closeBanner = () => {
        setBannerOpen(false);
        localStorage.setItem(BANNER_STORAGE_KEY, JSON.stringify(bannerOpen));
    }

    const bannerText = "Join us on Wednesday the 27th of November at 5pm (Pacific) for a webinar to discuss our mission.";

    return (
        <>
            {<Collapse in={bannerOpen}>
                <Alert
                    severity="info"
                    variant="filled"
                    onClose={closeBanner}>

                    <div className="flex flex-col md:flex-row items-center">
                        <span>{bannerText} <a href="https://zoom.us/webinar/register/WN_Q_WC2upUSGe24ve6UtjIhg" target="_blank" className="underline">Register now</a></span>
                    </div>
                </Alert>
            </Collapse>}
            <header className="px-20 py-[10px] bg-neutral-100 w-full hidden lg:block">
                <nav className="flex justify-center gap-8 items-center">
                    <img
                        src={logo}
                        alt="unplugged Logo"
                        className="cursor-pointer"
                        onClick={() => navigate('/')}
                    />
                    <div className="flex flex-1 items-center justify-center gap-6">
                        {paths.map((path) => (
                            <span
                                className="text-neutral-600 hover:underline cursor-pointer text-base"
                                onClick={() => navigate(`/${path}`)}
                            >
                                {path === 'Communities'
                                    ? 'Communities in action'
                                    : path}
                            </span>
                        ))}
                    </div>
                    <Button
                        className="rounded-[4px] text-lg"
                        onClick={() => navigate('/Pledge')}
                    >
                        SIGN THE PLEDGE
                    </Button>
                </nav>
            </header>

            <header className="flex justify-between px-4 py-[10px] bg-neutral-100 lg:hidden">
                <img
                    src={logoMobile}
                    alt="unplugged Logo"
                    className="cursor-pointer"
                    onClick={() => navigate('/')}
                />

                <Sheet>
                    <SheetTrigger>
                        <Menu size={24} />
                    </SheetTrigger>
                    <SheetContent className="px-6 flex flex-col gap-6 overflow-auto bg-slate-100">
                        <SheetHeader className="flex flex-row ">
                            <SheetTitle>
                                <img
                                    src={logo}
                                    alt="unplugged Logo"
                                    className="cursor-pointer"
                                    onClick={() => navigate('/')}
                                />
                            </SheetTitle>
                        </SheetHeader>

                        <nav className="flex flex-col gap-5 pt-5">
                            <div className="flex flex-col gap-5">
                                {paths.map((path) => (
                                    <span
                                        className="text-neutral-600 hover:underline border-b cursor-pointer text-base"
                                        onClick={() => navigate(`/${path}`)}
                                    >
                                        {path === 'Communities'
                                            ? 'Communities in action'
                                            : path}
                                    </span>
                                ))}
                            </div>
                            <Button
                                className="rounded-[4px]"
                                onClick={() => navigate('/Pledge')}
                            >
                                SIGN THE PLEDGE
                            </Button>
                        </nav>
                    </SheetContent>
                </Sheet>
            </header>
        </>
    )
}
